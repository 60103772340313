import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars } from '@fortawesome/free-solid-svg-icons';

import styles from './topbar.module.scss';


export function MenuButton(props) {
  return (
    <div onClick={() => {
      props.onclick();
    }} className={styles.menuButtonOuter}>
      <FontAwesomeIcon icon={faBars} />
    </div>
  );
}
