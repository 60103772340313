import React from 'react';
import styles from './forms.module.scss';
import classNames from 'classnames';
export function TextArea(props) {
  let classObj = {
    [styles.input]: true,
    [styles.disabled]: props.disabled === true
  };
  
  return (
    <div className={styles.inputContainer}>
      <label className={styles.label} htmlFor={props.id}>{props.label}</label>
      <textarea
        value={props.value}
        disabled={props.disabled===true}
        autoFocus={props.autoFocus === true}
        id={props.id}
        className={classNames(classObj)}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}></textarea>
      <div className={styles.details}>{props.details}</div>
    </div>
  );
}
