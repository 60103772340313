import React, { useState } from 'react';

import styles from './Navbar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export function NavbarButton(props) {
  const isActiveClass = props.isActive ? styles.selected : '';
  return (
    <div onClick={() => {}} className={[styles.navbarButtonOuter, isActiveClass].join(' ')}>
      <div className={[styles.navbarButtonTitle, isActiveClass].join(' ')}><span className={styles.iconContainer}>{<FontAwesomeIcon icon={props.icon} />}</span> {props.label}</div>
    </div>
  );
}
