import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './forms.module.scss';
// <FontAwesomeIcon icon={faCirclePlus} />
export function Button(props) {
  let classObj = {};
  classObj[styles.button] = true;
  classObj[styles.primary] = props.primary;
  classObj[styles.secondary] = !props.primary && props.secondary;
  classObj[styles.large] = props.large;
  classObj[styles.medium] = !props.large && props.medium;
  classObj[styles.small] = !props.large && !props.medium && props.small;
  classObj[styles.disabled] = props.disabled === true;
  const classes = classNames(classObj);
  return (
    <div className={classes} onClick={!props.disabled ? props.onClick : () => {}}> {props.label} </div>
  );
}