import React, { useEffect, useState } from 'react';
import { ClientDetails } from './AppDetails.utils';

import { Content } from '../../features/content/content';
import GameGlueAPI from '../../services/GameGlueAPI';
import styles from './AppDetails.module.scss';
import { withRouter } from '../../utils/withRounter';
import { withAuth } from "react-oidc-context";
import { TextInput, Button, TextArea, TagInput } from "../../features/forms";

import { connect } from 'react-redux';


class AppDetails extends React.Component {
  constructor(props) {
    super(props);
    this.clientDetails = new ClientDetails();
    this.clientId = this.props.router.params.clientId;
    console.log('Props: ', this.props);
    this.state = {
      isLoading: true,
      appDetails: {},
      updatePending: false,
      reviewPending: false
    };
  }
  async componentDidMount() {
    this.setState((state) => ({
      ...state,
        isLoading: true
    }));
    
    let gameGlueApi = new GameGlueAPI({
      apiToken: this.props.user.access_token
    });
    let response = await gameGlueApi.getAppDetails(this.clientId);
    if (response.status === 'success') {
      this.setState((state) => ({
        isLoading: false,
        appDetails: this.clientDetails.init(response.data)
      }));
    }
  }
  
  async updateAppDetails() {
    this.setState((state) => ({
      ...state,
      updatePending: true
    }));
    let gameGlueApi = new GameGlueAPI({
      environment: 'PROD',
      apiToken: this.props.user.access_token
    });
    let response = await gameGlueApi.updateAppDetails(this.clientId, this.state.appDetails);
    this.setState((state) => ({
      ...state,
      updatePending: false
    }));
  }
  
  async submitForReview() {
    this.setState((state) => ({
      ...state,
      reviewPending: true
    }));
    let gameGlueApi = new GameGlueAPI({
      apiToken: this.props.user.access_token
    });
    let response = await gameGlueApi.submitAppForReview(this.clientId);
  }
  
  u(updateFunc) {
    return (v) => {
      const updated = updateFunc(v);
      this.setState((state) => ({
        ...state,
        appDetails: updated
      }))
    }
  }
  
  convertUrlArrayToTags(urls) {
    if (!urls) {
      return [];
    }
    return urls.map((elem) => {
      return {id: elem, text: elem};
    });
  }
  
  render() {
    if (this.state.isLoading) {
      return <div className={styles.isLoading}>Loading...</div>
    }
    const metadata = this.state.appDetails?.clientMetadata;
    const submitButtonDisabled = metadata.awaiting_review || metadata.deployed || this.state.reviewPending;
    let submitButtonText = 'Submit for review';
    
    if (metadata.deployed) {
      submitButtonText = 'App is live';
    } else if (metadata.awaiting_review || this.state.reviewPending) {
      submitButtonText = 'Pending review...';
    }
    return (<Content title={this.state.appDetails?.clientMetadata?.name}>
      <div className={styles.appDetailsContainer}>
        <TextInput smallText disabled label={"Client ID"} value={this.clientId}/>

        <TextInput value={this.state.appDetails?.clientMetadata?.name} label={"Name"} details={'The name of your application. What users see in the GameGlue marketplace.'} onChange={this.u(this.clientDetails.updateName.bind(this.clientDetails))}/>
        <TextInput value={this.state.appDetails?.clientMetadata?.logo_url} label={"Logo URL"} details={'Logo to display in the marketplace, and the login consent screen. 150x150px is best.'} onChange={this.u(this.clientDetails.updateLogoUrl.bind(this.clientDetails))}/>
        <div className={styles.imageContainer}>
          <img className={styles.logoImage} src={this.state.appDetails?.clientMetadata?.logo_url} />
          <div>Logo preview</div>
        </div>
        {/*<TextInput smallText disabled label={"Client Secret"} value={this.state.appDetails?.clientDetails?.secret} details={"You likely won't need this."}/>*/}
        <TextArea value={this.state.appDetails?.clientMetadata?.description} label={"Description"} details={`Max Characters 500 (${this.state.appDetails?.clientMetadata?.description?.length || 0})`} onChange={this.u(this.clientDetails.updateDescription.bind(this.clientDetails))}/>
        <TextInput value={this.state.appDetails?.clientMetadata?.app_url} label={"Application URL"} details="Main URL of your app. Users will be sent here from the marketplace. This cannot be `localhost`." onChange={this.u(this.clientDetails.updateAppUrl.bind(this.clientDetails))}/>
        <TagInput
          tags={this.convertUrlArrayToTags(this.state.appDetails?.clientDetails?.redirectUris)}
          label={"Redirect URLs"}
          details={"URL to return to after a successful login."}
          onChange={this.u(this.clientDetails.updateRedirectUris.bind(this.clientDetails))}
        />
        <TagInput
          tags={this.convertUrlArrayToTags(this.state.appDetails?.clientDetails?.webOrigins)}
          label={"Web Origins"}
          details={"Origins where you plan to host your application. Comma separated. i.e. http://localhost:3000, https://www.mycoolapp.com"}
          onChange={this.u(this.clientDetails.updateWebOrigins.bind(this.clientDetails))}
        />
        <div className={styles.saveButtonContainer}>
          <Button disabled={this.state.updatePending} primary label={this.state.updatePending ? 'Working...' : 'Save Changes'} onClick={this.updateAppDetails.bind(this)}/>
          <Button disabled={submitButtonDisabled} primary label={submitButtonText} onClick={this.submitForReview.bind(this)}/>
        </div>
      </div>
    </Content>);
    
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps
  }
}

export default connect(mapStateToProps, null)(withAuth(withRouter(AppDetails)));
