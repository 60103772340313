import React, { useEffect, useState } from 'react';
import { Navbar } from './features/navbar/Navbar';

import { Routes, Route } from "react-router-dom";
import styles from './App.module.scss';
import { useAuth } from "react-oidc-context";
import { useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { useDispatch } from 'react-redux';

import {
  Apps, Documentation, Settings, NewAppPage, AppDetails
} from './pages';

import {
  selectIsMenuOpen,
  selectActivePageTitle,
  setUserData
} from './appSlice.js';

function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const isMenuOpen = useSelector(selectIsMenuOpen);
  const activePageTitle = useSelector(selectActivePageTitle);
  
  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }
  
  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }
  
  if (!auth.isAuthenticated) {
    auth.signinRedirect()
    return <div> Login required </div>;
  }
  
  const tokenDecoded = jwt_decode(auth.user.access_token);
  dispatch(setUserData({
    id: tokenDecoded.sub,
    ...tokenDecoded
  }))
  
  return (
      <div className={styles.app}>
        <div className={styles.navContainer}>
          <Navbar activePageTitle={activePageTitle} isOpen={isMenuOpen} /*user={user}*/ />
        </div>
        <div className={[styles.mainContainer,!isMenuOpen ? styles.menuClosed : ''].join(' ')}>
            <div id="main-container">
              <Routes>
                <Route index element={<Apps />} />
                <Route path="docs" element={<Documentation />} />
                <Route path="settings" element={<Settings />} />
                <Route path="apps/new" element={<NewAppPage />} />
                <Route path="apps/:clientId" element={<AppDetails />} />
              </Routes>
            </div>
        </div>
      </div>
  );
}
export default App;