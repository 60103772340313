import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
export function Markdown(props) {
  const [markdownText, setMarkdownText] = useState('');
  // useEffect with an empty dependency array (`[]`) runs only once
  useEffect(() => {
    fetch(props.url)
      .then((response) => response.text())
      .then((text) => {
        setMarkdownText(text);
      });
  }, []);
  return (
    <div className="markdown-body">
      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]} children={markdownText} />
    </div>
  );
}
