import React, { useState, useEffect } from 'react';
import { Markdown } from '../../features/markdown/Markdown';
import docsMarkdown from './documentation.md';
import { useDispatch } from 'react-redux';
import {
  changeActivePage
} from '../../appSlice';
import { PAGE_TITLES } from "../../_config/text";

export function Documentation() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeActivePage(PAGE_TITLES.DOCUMENTATION.EN));
  });
  return (
    <div>
      <Markdown url={docsMarkdown} />
    </div>
  );
}
