import React, { useState, useEffect } from 'react';
import { AppList } from './applist/AppList.js';
import styles from './Apps.module.scss';
import { useAuth } from "react-oidc-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import {
  changeActivePage,
  selectUser
} from '../../appSlice';

import GameGlueAPI from '../../services/GameGlueAPI';


import { PAGE_TITLES } from "../../_config/text";

export function Apps() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [apps, setApps] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();
  
  const user = useSelector(selectUser);
  const accessToken = auth.user?.access_token;
  
  
  useEffect(() => {
    getApps();
    dispatch(changeActivePage(PAGE_TITLES.MY_APPS.EN));

  }, []);
  
  const getApps = async (offset) => {
    let gameGlueApi = new GameGlueAPI({
      environment: 'PROD',
      apiToken: accessToken
    });
    let response = await gameGlueApi.getUsersApps(user.id);
    if (response.status === 'success') {
      setApps(response.data)
    }
    
    setIsLoading(false);
  };
  return (
    <div className={styles.appListPageOuter}>
      <div className={styles.appPageHeader}>
        <div className={styles.buttonContainer}>
          <Link to={'apps/new'}><div className={[styles.button, styles.buttonPrimary].join(' ')}> <FontAwesomeIcon icon={faCirclePlus} /> New App </div> </Link>
        </div>
      </div>
      <AppList isLoading={isLoading} apps={apps}/>
    </div>
  );
}