import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import 'react-tooltip/dist/react-tooltip.css'
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
const container = document.getElementById('root');
const root = createRoot(container);

const oidcConfig = {
  authority: "https://kc.gameglue.gg/realms/GameGlue",
  client_id: "GameGlue",
  redirect_uri: "https://developer.gameglue.gg",
  scope: 'profile username',
  onSigninCallback: () => {
     window.history.replaceState(
       {},
       document.title,
       window.location.pathname
     )
   }
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();