import React, { useState } from 'react';

import { MenuButton } from './menu.button';
import { useDispatch } from 'react-redux';
import {
  toggleMenu
} from '../../appSlice';

import styles from './topbar.module.scss';


export function Topbar(props) {
  const dispatch = useDispatch();
  return (
    <div className={[styles.topbarContainer, !props.isOpen ? styles.closed : ''].join(' ')}>
      <MenuButton onclick={() => dispatch(toggleMenu())} />
      <span className={styles.pageTitle}>{props.content}</span>
      {/*<div className={styles.welcomeMessage}>{props.user.email}</div>*/}
    </div>
  );
}
