import { URLS } from '../_config/env';
import axios from 'axios';

const ENV = 'PROD';

export default class GameGlueAPI {
  constructor(config) {
    this.environment = config.environment;
    this.apiToken = config.apiToken;
    this.apiUrl = URLS[ENV];
  }
  
  async createApp(name) {
      const options = {
        method: 'POST',
        url: `${this.apiUrl}/clients`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${this.apiToken}`,
          'cache-control': 'no-cache'
        },
        data: { name }
      };
      
      // TODO: Need to update this to handle expired apiTokens
      let result;
      try {
        const response = await axios(options);
        result = {status: 'success', data: response.data };
      } catch (err) {
        result = { status: 'error', msg: 'unknown' };
      }
      
      return result;
  }

  async getUsersApps(userId) {
    const authHeader = `Bearer ${this.apiToken}`;
    const options = {
      method: 'GET',
      url: `${this.apiUrl}/clients?user=${userId}`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${this.apiToken}`,
        'cache-control': 'no-cache'
      }
    };
    
    // TODO: Need to update this to handle expired apiTokens
    let result;
    try {
      const response = await axios(options);
      result = {status: 'success', data: response.data };
    } catch (err) {
      result = { status: 'error', msg: 'unknown' };
    }
    
    return result;
  }
  
  async getAppDetails(appId) {
    const options = {
      method: 'GET',
      url: `${this.apiUrl}/clients/${appId}`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${this.apiToken}`,
        'cache-control': 'no-cache'
      }
    };
    
    // TODO: Need to update this to handle expired apiTokens
    let result;
    try {
      const response = await axios(options);
      result = {status: 'success', data: response.data };
    } catch (err) {
      result = { status: 'error', msg: 'unknown' };
    }
    
    return result;
  }
  
  async updateAppDetails(appId, updates) {
    const updateObj = {
      name: updates?.clientMetadata.name,
      isDeployed: updates?.clientMetadata.isDeployed,
      description: updates?.clientMetadata.description,
      logo_url: updates?.clientMetadata.logo_url,
      app_url: updates?.clientMetadata.app_url,
      clientDetails: {
        redirectUris: updates?.clientDetails?.redirectUris,
        webOrigins: updates?.clientDetails?.webOrigins,
      }
    };
    
    const options = {
      method: 'PUT',
      url: `${this.apiUrl}/clients/${appId}`,
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${this.apiToken}`,
        'cache-control': 'no-cache'
      },
      data: updateObj
    };

    // TODO: Need to update this to handle expired apiTokens
    let result;
    try {
      const response = await axios(options);
      result = {status: 'success', data: response.data };
    } catch (err) {
      result = { status: 'error', msg: 'unknown' };
    }
    return result;
  }

  async submitAppForReview(appId) {
    const options = {
      method: 'PUT',
      url: `${this.apiUrl}/clients/${appId}/deploy`,
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${this.apiToken}`,
        'cache-control': 'no-cache'
      }
    };

    let result;
    try {
      const response = await axios(options);
      result = {status: 'success', data: response.data };
    } catch (err) {
      result = { status: 'error', msg: 'unknown' };
    }
    return result;
  }
  
}