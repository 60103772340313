import React, { useState, useEffect } from 'react';

import styles from './content.module.scss';

export function Content(props) {
  return (
    <div className={styles.contentOuter}>
      <div className={styles.title}> {props.title} </div>
      <div className={styles.contentContainer}>
        {props.children}
      </div>
    </div>
  );
}
