import React from 'react';
import { Link } from "react-router-dom";
import {faBook, faList, faSliders} from '@fortawesome/free-solid-svg-icons';
import { Topbar } from './topbar';

import styles from './Navbar.module.scss';
import logo from '../../assets/logo-ex-large.png';
import { NavbarButton } from './Navbar.button.js';

export function Navbar(props) {
  return (
    <div>
      <Topbar content={props.activePageTitle} isOpen={props.isOpen} /*user={props.user}*/ />
      <div className={[styles.navbarOuter, props.isOpen ? styles.closed : ''].join(' ')}>
        <div className={[styles.navbarContainer, !props.isOpen ? styles.closed : ''].join(' ')}>
          <img className={styles.logoImage} src={logo} alt="logo" />
          <div className="nav-bar-controls">
          </div>
          <Link to="/"><NavbarButton icon={faList} label="Apps" route="apps"/></Link>
          <a href="https://docs.gameglue.gg" target="_blank"><NavbarButton icon={faBook} label="Documentation" route="docs"/></a>
          <Link to="/settings"><NavbarButton icon={faSliders} label="Settings" route="settings"/></Link>
        </div>
      </div>
    </div>
  );
}
