import React, { useEffect } from 'react';
import styles from './forms.module.scss';
import classNames from 'classnames';
export function TextInput(props) {
 
  let classObj = {
    [styles.input]: true,
    [styles.disabled]: props.disabled === true,
    [styles.smallText]: props.smallText === true
  };
  
  return (
    <div className={styles.inputContainer}>
      <label className={styles.label} htmlFor={props.id}>{props.label}</label>
      <input value={props.value} disabled={props.disabled===true} autoFocus={props.autoFocus === true} id={props.id} className={classNames(classObj)} type="text" onChange={(e) => {
        props.onChange(e.target.value);
      }}/>
      <div className={styles.details}>{props.details}</div>
    </div>
  );
}
