import { set } from 'lodash';

export class ClientDetails {
  constructor() {
    this._raw = {};
    this._id = Math.round(Math.random()*10000);
    this._shouldLog = true;
  }
  init(rawData) {
    console.log('Raw: ', rawData);
    this._raw = rawData;
    this.log();
    return this._raw;
  }
  
  updateName(name) {
    set(this._raw.clientMetadata, 'name', name);
    this.log();
    return this._raw;
  }
  
  updateLogoUrl(url) {
    set(this._raw.clientMetadata, 'logo_url', url);
    this.log();
    return this._raw;
  }
  
  updateDescription(description) {
    set(this._raw.clientMetadata, 'description', description);
    this.log();
    return this._raw;
  }
  
  updateAppUrl(app_url) {
    set(this._raw.clientMetadata, 'app_url', app_url);
    this.log();
    return this._raw;
  }
  
  updateLoginUrl(url) {
    set(this._raw.clientDetails, 'initiate_login_uri', url);
    this.log();
    return this._raw;
  }

  updateRedirectUris(redirectUrisArray) {
    let arr = redirectUrisArray.map((elem) => {
      return removeTrailingSlashes(elem.text);
    });
    set(this._raw.clientDetails, 'redirectUris', arr);
    this.log();
    return this._raw;
  }
  
  updateAllowedOrigins(allowedOriginsArray) {
    let arr = allowedOriginsArray.map((elem) => {
      return removeTrailingSlashes(elem.text);
    });
    set(this._raw.clientDetails, 'allowed_origins', arr);
    this.log();
    return this._raw;
  }
  updateWebOrigins(webOriginsArray) {
    let arr = webOriginsArray.map((elem) => {
      return removeTrailingSlashes(elem.text);
    });
    set(this._raw.clientDetails, 'webOrigins', arr);
    this.log();
    return this._raw;
  }
  log() {
    if (this._shouldLog) {
      console.log('Raw: ', this._raw);
    }
  }
}
function removeTrailingSlashes(url) {
  if (url.endsWith('/')) {
    return url.replace(/\/+$/, '');
  }
  return url;
}