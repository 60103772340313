import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './applist.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark, faClock } from '@fortawesome/free-solid-svg-icons';

import { Content } from '../../../features/content/content';
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip'
export function AppList(props) {
  const apps = props.apps;
  const isLoading = props.isLoading;
  
  let content;
  if (isLoading) {
    content = <div className={styles.emptyText}> Loading... </div>;
  } else if (apps.length === 0) {
    content = <div className={styles.emptyText}>You don't have any apps. Click the 'New App' button to get started!</div>;
  } else {
  
    let divs = [];
  
    apps.forEach((elem, i) => {
      let icon;
      let iconText;
      if (elem.deployed) {
        icon = <FontAwesomeIcon icon={faCircleCheck}/>;
        iconText='Live';
      } else if (elem.awaiting_review) {
        icon = <FontAwesomeIcon icon={faClock}/>
        iconText='Pending review';
      } else {
        icon = <FontAwesomeIcon icon={faCircleXmark}/>
        iconText='Not deployed';
      }
      const deployedClassNames = {};
      deployedClassNames[styles.deployedContainer] = true;
      deployedClassNames[styles.isDeployed] = elem.deployed;
      deployedClassNames[styles.isAwaitingReview] = elem.awaiting_review;
      deployedClassNames['tooltips'] = true;
      let a = <div key={elem.id} className={styles.appRecordOuter}>
        <div data-tooltip-offset={-17} data-tooltip-content={iconText}  className={classNames(deployedClassNames)}>{icon}</div>
        <div className={styles.name}><Link to={`/apps/${elem.id}`}>{elem.name}</Link></div>
        <div className={styles.clientId}>Client ID: {elem.id}</div>
      </div>;
    
      divs.push(a);
    })
  
    content = divs;
    return (
      <Content title={"My apps"}>
        <div className={styles.appListContent}>
          {content}
          <Tooltip anchorSelect=".tooltips"/>
        </div>
      </Content>
    );
  }
}
