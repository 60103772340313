import React, { useState, useEffect } from 'react';
import styles from './forms.module.scss';
import classNames from 'classnames';
import { ReactTags } from '../../utils/react-tags/ReactTags';
const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
  tab: 9
};

export function TagInput(props) {
  const [hasFocus, setHasFocus] = useState(false);
  const [tags, setTags] = useState(props.tags);
  let classObj = {
    [styles.input]: true,
    [styles.disabled]: props.disabled === true,
    [styles.hasFocus]: hasFocus === true
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space, KeyCodes.tab];

  const handleInputBlur = () => {
    setHasFocus(false);
  };
  
  const handleInputFocus = () => {
    setHasFocus(true);
  };
  
  const handleDelete = i => {
    let newArr = tags.filter((tag, index) => index !== i);
    setTags(newArr);
    props.onChange(newArr);
  };

  const handleAddition = tag => {
    let newArr = [...tags, tag];
    setTags(newArr);
    props.onChange(newArr);
  };
  
  const classes = {
    tag: styles.tag,
    tags: styles.tags,
    remove: styles.remove,
    tagInput: styles.tagsInputFieldOuter,
    tagInputField: styles.tagsInputField
  };
  
  return (
    <div className={styles.inputContainer}>
      <label className={styles.label} htmlFor={props.id}>{props.label}</label>
      <div className={classNames(classObj)}>
        <ReactTags
          tags={props.tags}
          delimiters={delimiters}
          handleAddition={handleAddition}
          handleDelete={handleDelete}
          inputFieldPosition="inline"
          classNames={classes}
          placeholder={props.placeholder || 'Click here to edit'}
          allowDragDrop={false}
          handleInputFocus={handleInputFocus}
          handleInputBlur={handleInputBlur}
          autofocus={false}
        />
      </div>
      <div className={styles.details}>{props.details}</div>
    </div>
  );
}
