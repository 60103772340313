export const PAGE_TITLES = {
  MY_APPS: {
    EN: 'My Apps'
  },
  DOCUMENTATION: {
    EN: 'Documentation'
  },
  SETTINGS: {
    EN: 'Settings'
  },
  NEW_APP: {
    EN: 'Create Application'
  },
  APP_SETTINGS: {
    EN: 'Application Settings'
  }
};