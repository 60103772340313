import React, { useEffect, useState } from 'react';
import {
  changeActivePage
} from '../../appSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PAGE_TITLES } from "../../_config/text";
import { Content } from '../../features/content/content';
import { TextInput, Button } from '../../features/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faHourglass } from '@fortawesome/free-solid-svg-icons';
import styles from './NewApp.module.scss';
import { useAuth } from "react-oidc-context";

import GameGlueAPI from '../../services/GameGlueAPI';

export function NewAppPage() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [appName, setAppName] = useState({
    name: ''
  });
  const dispatch = useDispatch();
  
  const nav = useNavigate();
  
  useEffect(() => {
    dispatch(changeActivePage(PAGE_TITLES.NEW_APP.EN));
  });
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    createAppRequest(appName);
  };
  
  const createAppRequest = async (name) => {
    let gameGlueApi = new GameGlueAPI({
      environment: 'PROD',
      apiToken: auth?.user?.access_token
    });
    // TODO: Add error handling
    const result = await gameGlueApi.createApp(name);
    nav(`/apps/${result.data.id}`)
  };
  
  
  return (
    <Content title={'App Details'}>
      <div className={styles.newAppContainer}>
        <form onSubmit={handleSubmit}>
          <TextInput
            autoFocus
            id={'app-name'}
            label={'Name'}
            details={'Don\'t worry. You can change the name later in app settings.'}
            onChange={(value) => { setAppName(value) }}
          />
          <br />
          <div className={styles.buttonContainer}>
            <Link to="/"><Button label={<FontAwesomeIcon icon={faBackward} />} secondary  /></Link>
            <Button label={isLoading ? (<FontAwesomeIcon icon={faHourglass} />) : "Submit"} primary disabled={isLoading} onClick={handleSubmit} />
          </div>
        </form>
      </div>
    </Content>
  );
}
