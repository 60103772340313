import React, { useEffect } from 'react';
import LogoutButton from '../../features/logoutbutton/LogoutButton';
import AuthTestButton from '../../features/authtestbutton/AuthTestButton';
import { useDispatch } from 'react-redux';
import {
  changeActivePage
} from '../../appSlice';
import { PAGE_TITLES } from "../../_config/text";

import styles from './Settings.module.scss';

export function Settings() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeActivePage(PAGE_TITLES.SETTINGS.EN));
  });
  return (
    <div className={styles.settingsPageOuter}>
      <LogoutButton />
      <AuthTestButton />
    </div>
  );
}
