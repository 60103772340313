import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const AuthTestButton = () => {
  const [ accessToken , setAccessToken ] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  
  useEffect(() => {
    
    const getToken = async function() {
      const token = await getAccessTokenSilently({
        audience: `https://api.gameglue.gg`
      });
      setAccessToken(token);
    };
    
    getToken();
  });
  return (<button onClick={() => {
      axios.get('https://api.gameglue.gg/apps', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      }).then((res, err) => {
        console.log('Res: ', res);
        console.log('Err: ', err);
      });
    }
  }>Authorized?</button>);
};

export default AuthTestButton;